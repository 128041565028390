<template>
  <div>
    <b-form-group :disabled="formReadonly">
      <b-form-group
        label-cols="12"
        label-cols-lg="2"
        label-size="m"
        content-cols="12"
        content-cols-lg="6"
        label="照片"
      >
        <b-img
          v-if="invoiceLog.image"
          :src="invoiceLog.image.url"
          fluid
          thumbnail
        ></b-img>
      </b-form-group>
      <b-form-group
        label-cols="12"
        label-cols-lg="2"
        label-size="m"
        content-cols="12"
        content-cols-lg="6"
        label="會員"
      >
        <b-form-input v-model="invoiceLog.customer.name" disabled></b-form-input>
      </b-form-group>
      <b-form-group
        label-cols="12"
        label-cols-lg="2"
        label-size="m"
        content-cols="12"
        content-cols-lg="6"
        label="類型"
      >
        <b-form-select v-model="invoiceLog.type" :options="types"></b-form-select>
        <b-form-invalid-feedback :state="!v$.invoiceLog.type.$error">此欄位為必填</b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        label-cols="12"
        label-cols-lg="2"
        label-size="m"
        content-cols="12"
        content-cols-lg="6"
        label="發票字軌"
      >
        <b-form-input v-model="invoiceLog.no"></b-form-input>
        <b-form-invalid-feedback :state="!v$.invoiceLog.no.$error">此欄位為必填</b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        label-cols="12"
        label-cols-lg="2"
        label-size="sm"
        content-cols="12"
        content-cols-lg="6"
        label="開立時間"
      >
        <datepicker
          placeholder="Select Date"
          v-model="invoiceLog.issued_at"
          bootstrap-styling
          format="yyyy-MM-dd"
          :language="zh"
        ></datepicker>
        <b-form-invalid-feedback :state="!v$.invoiceLog.issued_at.$error">此欄位為必填</b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        label-cols="12"
        label-cols-lg="2"
        label-size="m"
        content-cols="12"
        content-cols-lg="6"
        label="隨機碼"
        v-if="showRandom"
      >
        <b-form-input v-model="invoiceLog.random"></b-form-input>
        <b-form-invalid-feedback :state="!v$.invoiceLog.random.$error">此欄位為必填</b-form-invalid-feedback>
      </b-form-group>
    </b-form-group>
    <b-form-group
      label-cols="12"
      label-cols-lg="2"
      label-size="m"
      content-cols="12"
      content-cols-lg="6"
      label="狀態"
      :disabled="statusReadonly"
    >
      <b-form-select v-model="invoiceLog.status" :options="statuses"></b-form-select>
    </b-form-group>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker"
import {zh} from "vuejs-datepicker/dist/locale"
import * as consts from '@/consts'
import useVuelidate from "@vuelidate/core";
import {required, requiredIf} from "@vuelidate/validators";

export default {
  setup: () => ({v$: useVuelidate()}),
  components: { Datepicker },
  props: {
    invoiceLog: {
      type: Object,
      default: () => {
        return {
          no: null,
          type: null,
          issued_at: null,
          random: null,
          customer: {},
        };
      },
    },
    formReadonly: {
      type: Boolean,
      default: false,
    },
    statusReadonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      zh,
      types: [
        { value: consts.INVOICE_LOG_TYPES.ELECTRONIC, text: '電子發票' },
        { value: consts.INVOICE_LOG_TYPES.TRADITIONAL, text: '傳統發票' },
      ],
      statuses: [
        { value: consts.INVOICE_LOG_STATUS.INIT, text: "等待處理" },
        { value: consts.INVOICE_LOG_STATUS.E_VERIFY, text: "電子發票 - 驗證中" },
        { value: consts.INVOICE_LOG_STATUS.E_VALID, text: "電子發票 - 已處理" },
        { value: consts.INVOICE_LOG_STATUS.E_INVALID, text: "電子發票 - 不存在" },
        { value: consts.INVOICE_LOG_STATUS.E_CANCEL, text: "電子發票 - 已作廢" },
        { value: consts.INVOICE_LOG_STATUS.T_VERIFY, text: "傳統發票 - 發票審核中 " },
        { value: consts.INVOICE_LOG_STATUS.T_VALID, text: "傳統發票 - 發票已審核" },
        { value: consts.INVOICE_LOG_STATUS.T_INVALID, text: "傳統發票 - 發票不合格" },
        { value: consts.INVOICE_LOG_STATUS.CANCEL, text: "發票已取消" },
      ],
    };
  },
  computed: {
    showRandom() {
      return this.invoiceLog.type === consts.INVOICE_LOG_TYPES.ELECTRONIC;
    },
  },
  validations() {
    return {
      invoiceLog: {
        no: {required},
        type: {required},
        issued_at: {required},
        random: {
          required: requiredIf(() => {
            return this.showRandom
          }),
        },
      }
    }
  },
  methods: {
		async validate () {
      return await this.v$.$validate();
    }
  },
};
</script>
